import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';
import Paper from '@material-ui/core/Paper/Paper';
import Grid from '@material-ui/core/Grid/Grid';

const totem = require('../../../Images/totem.jpg');

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  paragraph: {
    paddingLeft: theme.spacing.unit,
  },
});

const renderHeading = () => {
  return (
    <Typography variant={ 'title' } align={ 'center' }>
      Welcome to Jong-Jhun's portfolio!
    </Typography>
  );
};

const renderBody = (classes) => {
  return (
    <div style={ { marginTop: 16 } }>
      <Typography variant={ 'body1' } className={ classes.paragraph } paragraph>
        I'm a self-taught software developer, beginning with Java 8 and then adding on JavaScript.
        Over the past few years, I've used a bunch of different technologies on a bunch of different platforms.
      </Typography>
      <Typography variant={ 'body1' } className={ classes.paragraph } paragraph>
        Please take a look around as I'm using this website as a way to showcase myself as a developer.
        You can learn more about me in the 'About Me' tab, as well as, send an email, or view related links in the 'Contact Info' tab.
      </Typography>
      <Typography variant={ 'body1' } className={ classes.paragraph } paragraph>
        Thank you for visiting, and have a great day!
      </Typography>
    </div>
  );
};

class Home extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={ classes.root }>
          <Grid container spacing={ 8 }>
            <Grid container item md={ 6 } alignItems={'center'}>
              <img src={ totem } width="100%" alt="Totem.jpg" />
            </Grid>
            <Grid item md={ 6 }>
              { renderHeading() }
              { renderBody(classes) }
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(Home);
