import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid/Grid';

import Form from './Form/Form';
import Information from './Information/Information';

class ContactInfo extends Component {
  render() {
    return (
      <div>
        <Grid container spacing={ 16 }>
          <Grid item md={ 8 }>
            <Information />
          </Grid>
          <Grid item md={ 4 }>
            <Form />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ContactInfo;