import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Topbar from './TopBar/Topbar';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});

class Shell extends Component {
  render() {
    return (
      <Topbar />
    );
  };
}

export default withStyles(styles)(Shell);