import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper/Paper';
import Avatar from '@material-ui/core/Avatar/Avatar';
import Grid from '@material-ui/core/Grid/Grid';

import QuickFacts from './QuickFacts/QuickFacts';

const water = require('../../../../../Images/water.jpg');

const styles = theme => ({
  leftDiv: {
    paddingTop: theme.spacing.unit * 2,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  avatar: {
    height: 'auto',
    width: 'auto',
    marginBottom: theme.spacing.unit * 2,
  },
});

class Profile extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={ classes.leftDiv }>
        <Paper className={ classes.root } elevation={ 1 }>
          <Grid container spacing={ 8 } justify={ 'center' }>
            <Grid item xs={ 12 }>
              <Avatar src={ water } className={ classes.avatar } />
            </Grid>
            <Grid item xs={ 12 }>
              <QuickFacts />
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}


export default withStyles(styles)(Profile);
