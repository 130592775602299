import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid/Grid';

import Profile from './Profile/Profile';
import Summary from './Summary/Summary';

class AboutMe extends Component {
  render() {
    return (
      <div>
        <Grid container spacing={ 16 }>
          <Grid item md={ 4 }>
            <Profile />
          </Grid>
          <Grid item md={ 8 }>
            <Summary />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default AboutMe;
