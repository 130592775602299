import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid/Grid';
import Paper from '@material-ui/core/Paper/Paper';

import Shell from './Components/Shell/Shell';
import SocialMediaIcons from './Components/Shell/SocialMediaIcons/SocialMediaIcons';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    height: '87vh',
    overflow: 'auto',
  },
});

class App extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className="App">
        <Grid container>
          <Grid item md={ 2 } />
          <Grid item md={ 8 }>
            <Paper className={ classes.root }>
              <Grid item md={ 12 }>
                <Shell />
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={ 2 } />
          <Grid item xs={ 12 }>
            <SocialMediaIcons />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(App);
