import React, { Component } from 'react';
import { SocialIcon } from 'react-social-icons';
import Grid from '@material-ui/core/Grid/Grid';

class SocialMediaIcons extends Component {
  render() {
    return (
      <div style={ { paddingTop: 16 } }>
        <Grid container spacing={ 8 } justify={ 'center' }>
          <Grid item>
            <SocialIcon url="https://github.com/kimjongjhun"
                        style={ { height: 50, width: 50 } }
            />
          </Grid>
          <Grid item>
            <SocialIcon url="https://www.linkedin.com/in/jong-jhun-kim-a245a5119/"
                        style={ { height: 50, width: 50 } }
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default SocialMediaIcons;
