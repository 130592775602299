import React from 'react';

export const factsList = [
  {
    label: 'Location',
    value: 'Brandon, FL',
  },
  {
    label: 'Email Address',
    value: (
      <a href='mailto:kim.jongjhun@gmail.com' target={'blank'}>
        kim.jongjhun@gmail.com
      </a>
    ),
  },
];
