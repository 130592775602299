export const technologiesList = [
  '● JavaScript',
  '● AngularJS 1.5+',
  '● ReactJS',
  '● NodeJS',
  '● OOP',
  '● Java',
  '● MySQL',
  '● MongoDB',
  '● FireBase',
  '● Git',
  '● Mac OS / OSX',
  '● Linux',
  '● Windows',
  '● Android',
];