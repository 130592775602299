import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography/Typography';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import Grid from '@material-ui/core/Grid/Grid';

import { factsList } from './factsList.js';

class QuickFacts extends Component {
  render() {
    const renderQuickFacts = () => {
      return factsList.map((fact) => {
        return (
          <ListItem divider>
            <Grid container>
              <Grid item xs={ 4 }>
                { fact.label }
              </Grid>
              <Grid item xs={ 8 }>
                { fact.value }
              </Grid>
            </Grid>
          </ListItem>
        );
      });
    };

    return (
      <div>
        <Typography variant={ 'h6' } align={ 'center' } style={ { textDecoration: 'underline' } }>
          Quick Facts about me
        </Typography>
        <List>
          { renderQuickFacts() }
        </List>
      </div>
    );
  }
}

export default QuickFacts;
