import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { educationList } from './educationList';

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightMedium,
  },
});

class Education extends Component {
  render() {
    const { classes } = this.props;

    const renderEducation = (educationList) => {
      return educationList.map((education) => {
          return (
            <ListItem divider disableGutters>
              <Grid container>
                <Grid item md={ 3 }>
                  <Typography align={ 'center' }>
                    { education.level }
                  </Typography>
                </Grid>
                <Grid item md={ 9 }>
                  <Typography>
                    { education.name }
                    <br />
                    { education.fos }
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          );
        }
      );
    };

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={ <ExpandMoreIcon /> }>
          <Typography className={ classes.heading }>Education</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List style={ { width: '100%' } }>
            { renderEducation(educationList) }
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(Education);