/**
 * Add each new employment as an array, the most recent at the top.
 * The first object in the array should be the name and location.
 * Any other objects in the array should be information regarding that employment.
 */

export const employmentList = [
  [
    {
      name: 'United Systems and Software, Inc.',
      location: 'Lake Mary, FL',
    },
    {
      startDate: 'Mar. 2017',
      endDate: 'May 2017',
      position: 'Software Developer ',
      description: [
        '● Modernizing software using AngularJS 1.X and Bootstrap 3 from GreenScreen RPG format.',
        '● Made prototypes and proof of concepts of modernized and converted software.',
        '● Responsible for creating an entirely new UI from the ground up.',
        '● Made a GitLab server for a team of 10+ developers.',
      ],
    },
  ],
  [
    {
      name: 'FlexShopper, Inc.',
      location: 'Boca Raton, FL (Remote)',
    },
    {
      startDate: 'Sept. 2017',
      endDate: 'Oct. 2018',
      position: 'Software Developer ',
      description: [
        '● Maintained AngularJS 1.5 code that is v1 of an internal tool used by the Customer Service department.',
        '● Built in React that is v2 of the same internal tool.',
        '● Made components in both AngularJS and React.',
      ],
    },
  ],
  [
    {
      name: 'Powered Labs, LLC.',
      location: 'Tampa, FL',
    },
    {
      startDate: 'Jan. 2019',
      endDate: 'Apr. 2019',
      position: 'Software Developer ',
      description: [
        '● Lead architect of enterprise-level application built from the ground up for clients of various backgrounds in component-style architecture in ReactJS.',
        '● Interfaced with FireBase Database as the backend storage system.',
        '● Integrated Redux and completely overhauled file structure of application to something more manageable.',
        '● Worked closely on a small team, in a fast paced Scrum - Kanban hybrid environment.',
      ],
    },
  ],
  [
    {
      name: 'Florida Blue',
      location: 'Jacksonville, FL (Remote)',
    },
    {
      startDate: 'May 2019',
      endDate: 'Jan. 2020',
      position: 'Software Developer ',
      description: [
        '● Helped create a communication tool for internal users in ReactJS from scratch, to be used by hundreds of users.',
        '● Interfaced with many other systems within the Florida Blue network to get data or perform mission critical tasks.',
        '● Worked closely with business reps and product owners to help direct how stories and tasks are written.',
      ],
    },
  ],
];
