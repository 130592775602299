import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';

import { technologiesList } from './technologiesList';

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightMedium,
  },
  column: {
    flexBasis: '100%',
  },
});

const first = [], second = [], third = [];

class Technologies extends Component {
  divideTechnologies = () => {
    technologiesList.map((technology) => {
      const mod = technologiesList.indexOf(technology) % 3;

      switch (mod) {
        case 0: {
          first.push(technology);
          break;
        }

        case 1: {
          second.push(technology);
          break;
        }

        case 2: {
          third.push(technology);
          break;
        }

        default: {
          break;
        }
      }

      return null;
    });

    return (
      <Fragment>
        { this.renderColumns(first) }
        { this.renderColumns(second) }
        { this.renderColumns(third) }
      </Fragment>
    );
  };

  renderColumns = (technologies) => {
    const {classes} = this.props;

    return (
      <List className={ classes.column }>
        { technologies.map((tech) => {
          return (
            <ListItem dense>
              <ListItemText primary={ tech } />
            </ListItem>
          );
        }) }
      </List>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={ <ExpandMoreIcon /> }>
          <Typography className={ classes.heading }>Technologies</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          { this.divideTechnologies() }
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(Technologies);
