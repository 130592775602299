import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { employmentList } from './employmentList';

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightMedium,
  },
});

class EmploymentHistory extends Component {
  render() {
    const { classes } = this.props;

    const renderEmployments = () => {
      return employmentList.reverse().map(employment => (
        <ListItem divider disableGutters>
          <Grid container>
            <Grid item md={4}>
              <Typography>
                <b>{employment[0].name}</b>
                <br />
                {employment[0].location}
              </Typography>
            </Grid>
            {renderEmploymentInfo(employment[1], false)}
            {employment[2] && renderEmploymentInfo(employment[2], true)}
          </Grid>
        </ListItem>
      ));
    };

    const renderEmploymentInfo = (employment, spacer) => {
      return (
        <Fragment>
          {spacer && <Grid item md={4} />}
          <Grid item md={8}>
            <Typography>
              <b>{employment.position}</b>- {employment.startDate} to{' '}
              {employment.endDate}
            </Typography>
            <Typography gutterBottom>
              {renderDescriptions(employment.description)}
            </Typography>
          </Grid>
        </Fragment>
      );
    };

    const renderDescriptions = description => {
      return description.map(bullet => {
        return <Typography>{bullet}</Typography>;
      });
    };

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            Employment History
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List style={{ width: '100%' }}>{renderEmployments()}</List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(EmploymentHistory);
