import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Tab from '@material-ui/core/Tab/Tab';
import { Link } from 'react-router-dom';

import Sections from '../Sections/Sections';

const styles = theme => ({
  grow: {
    flexGrow: 1,
    textDecoration: 'none',
  },
  sections: {
    height: '100%',
  },
  div: {
    flexDirection: 'column'
  },
});

class Topbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 'home',
    };
  };

  render() {
    const { classes } = this.props;

    const handleChange = (event, value) => {
      if (this.state.value !== value) {
        this.setState({
          value: value,
        });
      }
    };

    const setHome = () => {
      this.setState({
        value: 'home',
      });
    };

    return (
      <div className={ classes.div }>
        <AppBar position="static">
          <Toolbar>
            <Typography to="/"
                        variant="h4"
                        color="inherit"
                        className={ classes.grow }
                        component={ Link }
                        onClick={ setHome }
            >
              JONG-JHUN KIM
            </Typography>
            <Tabs onChange={ handleChange } value={ this.state.value }>
              <Tab to="/AboutMe" value="aboutMe" label="About Me" component={ Link } />
              <Tab to="/ContactInfo" value="contactInfo" label="Contact Info" component={ Link } />
            </Tabs>
          </Toolbar>
        </AppBar>
        <Sections value={ this.state.value } className={ classes.sections }/>
      </div>
    );
  };
}

export default withStyles(styles)(Topbar);