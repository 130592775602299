import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightMedium,
  },
  paragraph: {
    textIndent: theme.spacing.unit * 2,
    lineHeight: 2,
  },
});

class Synopsis extends Component {
  render() {
    const { classes } = this.props;

    const renderBody = () => {
      return (
        <div>
          <Typography variant={'body1'} className={classes.paragraph}>
            I am a self-taught developer, having focused primarily on frontend
            development in ReactJS, as of late. I also have experience using
            vanilla JS and AngularJS 1.5+. On the backend, most of my experience
            has been for personal projects in NodeJS using either Express or
            Hapi.
          </Typography>
          <Typography variant={'body1'} className={classes.paragraph}>
            I have worked on teams and for companies of all shapes and sizes.
            From small startups to large thousand employee plus companies,
            sometimes being on a team of 5 people, sometimes on a team of 50
            people. I also have over two years of remote development experience,
            and just as much onsite development experience.
          </Typography>
          <Typography variant={'body1'} className={classes.paragraph}>
            I'm always on the hunt to learn new and interesting technologies,
            and exploring opportunities to grow as a developer, and further my
            software development career. If you have any interesting positions
            available, please feel free to contact me using the contact form on
            the contact tab.
          </Typography>
        </div>
      );
    };

    return (
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Summary</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{renderBody()}</ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(Synopsis);
