import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Synopsis from './Synopsis/Synopsis';
import Education from './Education/Education';
import EmploymentHistory from './EmploymentHistory/EmploymentHistory';
import PrimaryTechnologies from './Technologies/Technologies';

const styles = theme => ({
  rightDiv: {
    paddingTop: theme.spacing.unit * 2,
  },
});

class Summary extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={ classes.rightDiv }>
        <Synopsis />
        <PrimaryTechnologies/>
        <EmploymentHistory />
        <Education />
      </div>
    );
  }
}

export default withStyles(styles)(Summary);
