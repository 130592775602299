import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AboutMe from './AboutMe/AboutMe';
import ContactInfo from './ContactInfo/ContactInfo';
import Home from '../Home/Home';

const Sections = () => {
  return (
    <Switch>
      <Route exact path='/' component={ Home } />
      <Route path='/AboutMe' component={ AboutMe } />
      <Route path='/ContactInfo' component={ ContactInfo } />
    </Switch>
  );
};

export default Sections;
