import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Recaptcha from 'react-recaptcha';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import Paper from '@material-ui/core/Paper/Paper';
import Button from '@material-ui/core/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
  },
  div: {
    paddingTop: theme.spacing.unit * 2,
  },
  button: {
    marginTop: theme.spacing.unit,
  },
  buttonProgress: {
    position: 'relative',
    marginLeft: -12,
    left: '50%',
  },
});

class Form extends Component {
  state = {
    form: {
      name: '',
      email: '',
      desc: '',
    },
    loading: false,
    success: undefined,
    isVerified: false,
  };

  handleCaptcha = () => {
    console.log('captcha hit');

    this.setState({
      ...this.state,
      isVerified: true,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      ...this.state,
      loading: true
    });

    const form = Object.assign({}, this.state);

    axios.post('https://backend-pxavwjkrtl.now.sh/api/email', form)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            ...this.state,
            loading: false,
            success: true,
            isVerified: false,
          });

          setTimeout(() => this.setState({
            ...this.state,
            success: undefined,
          }), 5000);
          document.getElementById('contactForm').reset();
        }
      })
      .catch((err) => {
        console.log('err caught', err);
        this.setState({
          ...this.state,
          loading: false,
          success: false,
          isVerified: false,
        });

        setTimeout(() => this.setState({
          ...this.state,
          success: undefined,
        }), 5000);
      });
  };

  handleChange = () => {
    const form = Object.assign({}, this.state.form);

    form.name = document.getElementById('name').value;
    form.email = document.getElementById('email').value;
    form.desc = document.getElementById('description').value;

    this.setState({
      ...this.state,
      form: form,
    });
  };

  renderStatus = (status) => {
    if (status === true) {
      return (
        <Typography style={ { color: 'green' } } align={ 'center' }>
          Email has been sent successfully!
        </Typography>
      );
    }

    return (
      <Typography style={ { color: 'red' } } align={ 'center' }>
        There was an error while sending your email,
        <br />
        please try again
      </Typography>
    );
  };

  render() {
    const { classes } = this.props;
    const { loading, success, isVerified } = this.state;

    return (
      <div className={ classes.div }>
        <Paper className={ classes.root }>
          <Typography variant={ 'h6' } align={ 'center' }>
            For business inquiries,
            <br />
            please send me an email
          </Typography>
          <Grid container>
            <Grid item xs={ 12 }>
              { success === true && this.renderStatus(success) }
              { success === false && this.renderStatus(success) }
              { loading ?
                <CircularProgress className={ classes.buttonProgress } size={ 24 } /> :
                <Fragment>
                  <form id={ 'contactForm' } onSubmit={ this.handleSubmit } onChange={ this.handleChange }>
                    <TextField id={ 'name' }
                               variant={ 'outlined' }
                               label={ 'Name' }
                               margin={ 'dense' }
                               fullWidth
                               type={ 'text' }
                               required
                    />
                    <TextField id={ 'email' }
                               variant={ 'outlined' }
                               label={ 'Email' }
                               margin={ 'dense' }
                               fullWidth
                               type={ 'email' }
                               required
                    />
                    <TextField id={ 'description' }
                               multiline rows={ 5 }
                               variant={ 'outlined' }
                               label={ 'Description' }
                               margin={ 'dense' }
                               fullWidth
                               type={ 'text' }
                               required
                    />
                    <div style={{ marginTop: 8}}
                    >
                      <Recaptcha sitekey="6Lc6I3sUAAAAALOzxeR8jlG21bn0HDbp9Sbq8VzL"
                                 render={'explicit'}
                                 verifyCallback={this.handleCaptcha}
                      />
                      <Button className={ classes.button }
                              variant={ 'outlined' }
                              color={ 'default' }
                              type={ 'submit' }
                              disabled={ !isVerified || loading }
                      >
                        Send Email
                      </Button>
                    </div>
                  </form>
                </Fragment>
              }
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(Form);
