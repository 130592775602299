export const educationList = [
  {
    level: 'High School',
    name: 'Hillsborough Senior High School',
    years: '2007 - 2010',
    fos: 'International Baccalaureate',
  },
  {
    level: 'University',
    name: 'University of Central Florida',
    years: '2010 - 2015',
    fos: "Bachelor's of Science - Health Sciences: Pre-Clinical",
  },
];